.App{
  display:grid;
  
}
.App-header {
  margin-top: 0;
  margin-bottom: 0;
  justify-content: left;
  font-style:normal;
  font-size: calc(60px);
  color: white;
}
.bio{
  text-align: center;
  margin: 10px 0;
  font-size: 16px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  line-height: 1.6em;
  font-weight: bolder;
  margin-right: auto;
}
.container{
  width: 80%;
  margin: auto;
  overflow: hidden;
}
#mainNav{
   color: black;
}
#mainNav ul{
  padding: 0;
  margin:0;
  display: flex;
  justify-content: center;
  justify-content: space-evenly;
}
#mainNav li{
  display: flex;
  padding:3% 3%;
  border-radius: 100%;        
  border-style: solid;
  display: block;
}
#jumbo{
  background-position:center center;
  min-height: 300px;
  text-align: center;
}
#jumbo h1{
  color:white;
  font: size 45px;
  padding-top: 20px;
  line-height: 1.6em;
}