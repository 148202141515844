#hero{
    background-image: url(/resources/tyler.jpg);
      background-repeat: no-repeat;
      background-size: 100%;
      border-style: double;
      height: 220px;
      width: 220px;
      border-radius: 50%;
}
.photo-Link{
    display:flex;
    justify-content: center;
}